<template>
    <div class="h-full">
		<!-- Table & Timeline -->
		<a-row :gutter="24" class="h-full">
			<!-- Table -->
			<a-col :span="24" :lg="24" class="h-full">
				<a-card :bordered="false"  class="header-solid h-full" :bodyStyle="{height:'100%',padding: 0}">
					<div style="padding:30px 0 0 12px ;">
						<div>
							<a-radio-group v-model="engine">
								<a-radio-button value="openai">
									OpenAi
								</a-radio-button>
								<a-radio-button value="baidu_api">
									百度
								</a-radio-button>
								<a-radio-button value="youdao_api">
									有道
								</a-radio-button>
								<a-radio-button value="youdao">
									有道破解版
								</a-radio-button>
							</a-radio-group>
						</div>
						<div style="padding-top: 30px;">
							<a-select
								size="small"
								show-search
								option-filter-prop="children"
								style="width: 200px"
								default-value="auto"
								@change="handleChange1"
							>
								<a-select-option v-for="(item,index) in lans" :key="index" :value="index">
								{{item.name}}
								</a-select-option>
							</a-select>
							<a-icon type="swap" style="padding-left: 50px;padding-right: 50px;" />
							<a-select
								size="small"
								show-search
								option-filter-prop="children"
								style="width: 200px"
								default-value="auto"
								@change="handleChange2"
							>
								<a-select-option v-for="(item,index) in lans" :key="index" :value="index">
								{{item.name}}
								</a-select-option>
							</a-select>
							<a-button type="primary" :disabled="disabled" size="small" icon="robot" style="margin-left: 50px;"  @click="toTran()">
								翻译
							</a-button>
						</div>
					</div>
					<div style="padding-top: 30px;">
						<a-col :span="24" :lg="10" class="h-full">
							<a-textarea placeholder="输入文字即可翻译" v-model="text" allow-clear :autoSize="{ minRows: 15}"/>
						</a-col>
						<a-col :span="24" :lg="10" class="h-full">
							<a-textarea placeholder="输入文字即可翻译" v-model="new_text" allow-clear :autoSize="{ minRows: 15}"/>
						</a-col>
					</div>
				</a-card>
			</a-col>
			<!-- / Table -->
		</a-row>
		<!-- / Table & Timeline -->
	</div>
</template>

<script>
	import axios from 'axios'
	export default ({
		data() {
			return {
				lans:[],
				engine:'openai',
				text:'',
				to:'',
				fr:'',
				new_text:'',
				disabled:false
			}
		},
		methods: {
			handleChange1(v){
				this.fr = v
			},
			handleChange2(v){
				this.to = v
			},
			getLan(){
				axios.get(
					'http://ai.tool.winndoo.cn/api/translate_api'
				).then(response => {
					if(response.data.errno == 0){
						this.lans = response.data.data
					}
				}).catch(error => {
					
				})
			},
			toTran(){
				this.room_id = this.$route.query.room_id
				let user_info = localStorage.getItem('user_info')
				user_info = JSON.parse(user_info)
				let data = {
					"text": this.text,
					"engine": this.engine,
					"to": this.to,
					"fr": this.fr,
					"user_id": user_info.user_id
				}
				this.disabled = true
				axios.post(
					'http://ai.tool.winndoo.cn/api/translate_api',
					data
				).then(response => {
					if(response.data.errno == 0){
						this.new_text = response.data.data.trans
					}
					this.disabled = false
				}).catch(error => {
					this.disabled = false
					
				})
			}
		},
		mounted() {
			this.getLan()
		}
	})
</script>